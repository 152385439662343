import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getPaymentSelectedFilters,
  PaymentSelectedFiltersConstants,
  PaymentSelectedFiltersState,
  setFilterValue,
} from '../../../store/invoices/paymentSelectedFiltersSlice'
import FilterChips, { FilterChipData } from '../../FilterChips/FilterChips'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'

const getChipsData = (
  filters: PaymentSelectedFiltersState,
): FilterChipData<string>[] => {
  // Check if each filter has values and add it to the array.
  const chipsData = []

  if (filters.paymentStatus?.length > 0) {
    const statuses = filters.paymentStatus.map((i: string) => ({
      property: PaymentSelectedFiltersConstants.paymentStatus,
      value: i,
    }))
    chipsData.push(...statuses)
  }

  if (filters.paymentDateRange.length > 0) {
    chipsData.push({
      property: PaymentSelectedFiltersConstants.paymentDates,
      value: filters.paymentDateRange,
    })
  }

  if (filters.paymentAmountFrom) {
    chipsData.push({
      property: PaymentSelectedFiltersConstants.paymentAmountFrom,
      value: formatCurrency(filters.paymentAmountFrom, filters.currency),
    })
  }

  if (filters.paymentAmountTo) {
    chipsData.push({
      property: PaymentSelectedFiltersConstants.paymentAmountTo,
      value: formatCurrency(filters.paymentAmountTo, filters.currency),
    })
  }

  if (filters.client) {
    chipsData.push({
      property: PaymentSelectedFiltersConstants.client,
      value: filters.client.name,
    })
  }

  return chipsData as FilterChipData<string>[]
}

interface PaymentsChipsProps {
  changeFilterAndSearch: (filterValue: string) => void
  clearCategoryFilter: (category: string) => void
  clearAllFilters: () => void
}

const PaymentsChips = ({
  changeFilterAndSearch,
  clearCategoryFilter,
  clearAllFilters,
}: PaymentsChipsProps) => {
  const selectedFilters = useSelector(getPaymentSelectedFilters)

  const [filterChipsData, setFilterChipsData] = useState<
    FilterChipData<string>[]
  >([])

  useEffect(() => {
    const chipsData = getChipsData(selectedFilters)
    setFilterChipsData(chipsData)
  }, [selectedFilters])

  const handleDeleteChip = useCallback(
    (chipData: FilterChipData<string>) => {
      changeFilterAndSearch(
        setFilterValue(chipData.property as string, chipData.value),
      )
    },
    [clearCategoryFilter, changeFilterAndSearch, setFilterValue],
  )

  return (
    <FilterChips<string>
      filtersSelected={filterChipsData}
      filterChipTranslationKeyPrefix="Invoicing.FilterGroups"
      onChipDeleted={handleDeleteChip}
      onClearAll={clearAllFilters}
      clearAllFocusQuerySelector="#payments-filter-chips-clear-all-focus-target input"
    />
  )
}

export default PaymentsChips
