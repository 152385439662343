import { Theme } from '@mui/material/styles'
import tokens from '../../styles/tokens.json'

export const userAppsStyles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '2.25rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontFamily: 'Prelo',
    fontStyle: 'bold',
    color: theme.palette.text.primary,
    background: tokens.colors.white,
    width: '100%',
    justifyContent: 'center',
    minHeight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    pt: 2,
  },
  circularProgress: {
    color: theme.palette.primary.main,
    position: 'relative',
    left: '50%',
  },
  rightLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    fontWeight: 500,
    float: 'right',
    px: 0.5,
  },
  centerBtnBox: {
    display: 'flex',
    justifyContent: 'center',
    mt: '2.5rem',
    px: 6,
    py: 3,
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    textWidth: '1rem',
    marginLeft: '0rem',
    [theme.breakpoints.only('tablet')]: {
      marginLeft: '3.125rem',
    },
  },
  card: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '1.6875rem',
    backgroundColor: tokens.colors.midnightBlue,
    filter: 'drop-shadow(0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25))',
    color: tokens.colors.white,
    width: '15.625rem',

    [theme.breakpoints.only('desktop')]: {
      maxWidth: '17rem',
      maxHeight: '14.1875rem',
      height: 'fit-content',
      border: `.0625rem solid ${tokens.colors.rsmGray.disabled}`,
    },
    [theme.breakpoints.only('tablet')]: {
      marginTop: '0rem',
      border: `.0625rem solid ${tokens.colors.rsmGray.disabled}`,
      maxHeight: '14.0625rem',
    },
    [theme.breakpoints.up('tablet')]: {
      marginBottom: '1.875rem',
    },
    [theme.breakpoints.only('mobile')]: {
      minWidth: '17rem',
      maxHeight: '10.9375rem',
      width: '100%',
      marginBottom: '.75rem',
      marginRight: 0,
      marginTop: 0,
      padding: '0rem',
      border: 0,
    },
  },
})

export default userAppsStyles
