import React, { useMemo, useState, useEffect } from 'react'
import { Box, TextField, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  FormState,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form'
import { Styles } from '../../../../types'
import { useGetUserContactPreferenceDetailsQuery } from '../../../../store/userService'
import { tokens } from '../../../../styles/materialTheme'

const styles: Styles = {
  paddingContainer: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '50%',
    },
    marginBottom: '2.5rem',
  }),
  error: {
    display: 'inline',
    float: 'left',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '.875rem',
    fontWeight: 400,
    color: tokens.colors.rsmRed.secondary,
    paddingTop: '0rem',
  },
}

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const StyledTextField = styled(TextField)({
  '.MuiInputBase-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiFormHelperText-root': { margin: '0rem' },
})

interface FormBusinessPhoneNumberProps {
  required: boolean
  control: Control<FieldValues, any>
  formState: FormState<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  getValues?: UseFormGetValues<FieldValues> | undefined
  setPhoneNumber?: React.Dispatch<React.SetStateAction<string>> | undefined
}

const FormBusinessPhoneNumber = ({
  required,
  control,
  formState,
  setValue,
  getValues = undefined,
  setPhoneNumber = undefined,
}: FormBusinessPhoneNumberProps) => {
  const { t } = useTranslation()
  const { data } = useGetUserContactPreferenceDetailsQuery()
  const storedValue = getValues !== undefined ? getValues('businessPhone') : ''
  const phone =
    data?.data.businessPhone || storedValue !== '' ? storedValue : ''
  const [businessPhoneError, setBusinessPhoneError] = useState('')

  const [editingFormBusinessPhone, setEditingFormBusinessPhone] =
    useState(false)

  useEffect(() => {
    setValue('businessPhone', phone)
  }, [phone])

  useEffect(() => {
    setEditingFormBusinessPhone(false)
  }, [formState.isSubmitting])

  const ariaProps = useMemo(
    () => (required ? { 'aria-required': true } : {}),
    [required],
  )

  const checkBusinessPhoneError = (error: FieldError | undefined): boolean => {
    if (error && error.message && error.message.length > 0) {
      setBusinessPhoneError(error.message)
      return true
    }
    setBusinessPhoneError('')
    return false
  }

  return (
    <Box
      style={{ padding: '0 1.4375rem' }}
      sx={styles.paddingContainer}
      data-testid="Div_Support_BusinessPhoneContainer">
      <StyledLabel htmlFor="businessPhone">
        <span data-testid="Lbl_Support_BusinessPhoneLabel">
          {t('BusinessPhone')}
          {required ? <>&nbsp;*</> : null}
        </span>
      </StyledLabel>
      <Controller
        name="businessPhone"
        control={control}
        defaultValue={phone}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <InputMask
            mask="(999) 999-9999"
            value={value}
            onFocus={() => setEditingFormBusinessPhone(true)}
            onBlur={() => setEditingFormBusinessPhone(false)}
            onChange={(e) => {
              setEditingFormBusinessPhone(true)
              if (setPhoneNumber) {
                setPhoneNumber(e.target.value)
              }
              onChange(e)
            }}>
            <StyledTextField
              id="businessPhone"
              error={checkBusinessPhoneError(error)}
              placeholder="(111) 111-1111"
              inputRef={ref}
              inputProps={{
                ...ariaProps,
                'data-testid': 'Inp_Support_BusinessPhoneValue',
              }}
              helperText={
                <Typography sx={styles.error}>{error?.message}</Typography>
              }
            />
          </InputMask>
        )}
        rules={{
          required: required
            ? `${t('BusinessPhone')} ${t('IsARequiredField')}`
            : false,
          validate: (value) => {
            const evalue = (value || '').replace(/[^0-9]/g, '')
            if (required) {
              if (evalue.length > 0 && !editingFormBusinessPhone) {
                const isValid = /^[0-9]{10}$/.test(evalue) // Checks that phone number has the valid number of digits
                if (!isValid) return t('ValidPhoneNumber').toString()
              }
              if (evalue.length === 0) {
                return businessPhoneError !== t('ValidPhoneNumber').toString()
                  ? businessPhoneError
                  : t('ValidPhoneNumber').toString()
              }
              return true
            }
            return true
          },
        }}
      />
    </Box>
  )
}

export default FormBusinessPhoneNumber
