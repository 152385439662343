import React from 'react'

const RsmBarIcon = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 229 2">
    <path
      d="m86,2h143V0H86v2Z"
      fill="#009add"
      fillRule="evenodd"
      strokeWidth="0"
    />
    <path
      d="m0,2h21V0H0v2Z"
      fill="#63666a"
      fillRule="evenodd"
      strokeWidth="0"
    />
    <path
      d="m24,2h58V0H24v2Z"
      fill="#4b9c2e"
      fillRule="evenodd"
      strokeWidth="0"
    />
  </svg>
)

export default RsmBarIcon
