/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import ContactUsMenu from 'src/components/domain/Support/SupportForm/ContactUsMenu'
import processAdobePageView from '../../analytics/adobe/pageView'
import { UserType } from '../../analytics/adobe/types'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import MobileMenu from '../../components/Navigation/MobileMenu/MobileMenu'
import { DropdownObject } from '../../components/Dropdown/Dropdown'

function parseLocationKey(path: string): string {
  const fragments = path?.split('/')
  if (fragments?.length > 1) return fragments[2]
  return path ?? ''
}

const ContactUsBaseLayout = () => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const userType: UserType = userInfo.isEmployee ? 'Internal' : 'External'
  const { pathname } = useLocation()
  const { isMobile } = useDeviceType()
  const mobileDropdownData: DropdownObject[] = useMemo(() => {
    const menuItems = []
    menuItems.push(
      {
        value: '/contact-us/support-page',
        display: t('Navigation.Support'),
      },
      { value: '/contact-us/faq', display: t('Navigation.FAQ') },
      {
        value: '/contact-us/feedback',
        display: t('Navigation.Feedback'),
      },
    )
    return menuItems
  }, [])

  useAITracking(t('Invoicing.ECPInvoicingTitle'), window.location.href)

  useEffect(() => {
    document.title = t('Invoicing.ECPInvoicingTitle')
  }, [])

  useEffect(() => {
    if (pathname?.length > 0) processAdobePageView(pathname, userType)
  }, [pathname])

  return (
    <>
      <Box>
        <MainMenu
          currentItem="/contact-us"
          subNavSlot={
            isMobile && (
              <MobileMenu
                dropdownData={mobileDropdownData}
                currentSelected={parseLocationKey(pathname)}
                dropdownName="ContactUsSubNavigation"
                dropdownAriaLabel="Contact Us Sub Navigation"
                ariaLabel="Contact Us menu"
              />
            )
          }
        />
      </Box>
      {!isMobile && <ContactUsMenu currentItem={parseLocationKey(pathname)} />}
      <MainContent>
        <Outlet />
      </MainContent>
    </>
  )
}

export default ContactUsBaseLayout
