import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Button, Box, Typography, styled, TextField } from '@mui/material'
import { Styles } from '../../types'
import tokens from '../../styles/tokens.json'
import FormPreferredContactRadio from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormFeedbackRadioButtons'
import FormAutoPopEmail from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormAutoPopEmail'
import FormBusinessPhoneNumber from '../../rsmCoreComponents/components/DynamicForm/FormControls/FormBusinessPhoneNumber'

const FeedbackForm = () => {
  const { t } = useTranslation()
  const { control, handleSubmit, formState, setValue } = useForm()
  const [preferredContact, setPreferredContact] = useState('Email')

  const styles: Styles = {
    formContainer: (theme) => ({
      backgroundColor: tokens.colors.white,
      padding: '2rem',
      color: tokens.colors.rsmGray.copy,
      borderRadius: '0.25rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      maxWidth: '95%',
      margin: '0 auto',
      [theme.breakpoints.down('tablet')]: {
        padding: '1rem',
        maxWidth: '100%',
      },
      [theme.breakpoints.down('mobile')]: {
        padding: '0.5rem',
        margin: '0',
        border: 'none',
      },
    }),
    header: {
      fontFamily: 'Prelo-Bold, sans-serif',
      fontSize: '2rem',
      marginBottom: '1rem',
    },
    inputField: (theme) => ({
      '& .MuiInputBase-root': {
        padding: '0.5rem',
        border: `0.0625rem solid ${tokens.colors.rsmGray.disabled}`,
        borderRadius: '0.25rem',
      },
      [theme.breakpoints.down('tablet')]: {
        '& .MuiInputBase-root': {
          padding: '0.5rem',
        },
      },
      [theme.breakpoints.down('mobile')]: {
        '& .MuiInputBase-root': {
          padding: '0.3125rem',
          fontSize: '0.9rem',
        },
      },
    }),
    radioGroup: (theme) => ({
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      [theme.breakpoints.down('tablet')]: {
        flexDirection: 'column',
        gap: '0.5rem',
      },
    }),
    contactMethodContainer: (theme) => ({
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem',
      [theme.breakpoints.down('tablet')]: {
        flexDirection: 'column',
      },
    }),
    submitButton: (theme) => ({
      alignSelf: 'center',
      backgroundColor: tokens.colors.rsmGreen.primary,
      color: 'white',
      [theme.breakpoints.down('tablet')]: {
        flexDirection: 'column',
      },
    }),
    error: {
      display: 'inline',
      float: 'left',
      fontFamily: 'Prelo-Book, sans-serif',
      fontSize: '.875rem',
      fontWeight: 400,
      color: tokens.colors.rsmRed.secondary,
      paddingTop: '0rem',
    },
  }

  const handleRadioChange = (value: string) => {
    setPreferredContact(value)
  }

  const StyledLabel = styled('label')(({ theme }) => ({
    fontFamily: 'Prelo-Black, sans-serif',
    fontSize: '1rem',
    paddingBottom: '0rem',
    marginBottom: '0.1rem',
    color: theme.palette.text.primary,
    display: 'block',
  }))

  const issueTextLimit = 2000
  const StyledTextField = styled(TextField)({
    '.MuiInputBase-root': {
      width: '100%',
      maxWidth: '100%',
    },
    '& .MuiFormHelperText-root': { margin: '0rem' },
  })

  const onSubmit = () => {
    alert('Feedback submitted successfully!')
  }

  const renderBusinessEmail = () => <FormAutoPopEmail />
  const renderBusinessPhoneNumber = () => (
    <FormBusinessPhoneNumber
      formState={formState}
      setValue={setValue}
      control={control}
      required={preferredContact === 'Phone'}
    />
  )

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={styles.formContainer}>
      <Typography
        sx={{ paddingBottom: '1rem' }}
        variant="h4"
        component="h1"
        data-testid="Lbl_Feedback_Title">
        {t('FeedbackPage.Title')}
      </Typography>
      <Typography variant="body1" data-testid="Lbl_Feedback_Description">
        {t('FeedbackPage.Description')}
      </Typography>
      <Typography>
        <StyledLabel htmlFor="feedbackDetails">
          <span data-testid="Lbl_FeedbackDetails">
            <Trans
              i18nKey="FeedbackPage.FeedbackDetailsLabel"
              components={{ b: <b /> }}
            />
          </span>
        </StyledLabel>

        <Controller
          name="feedbackDetails"
          control={control}
          defaultValue=""
          rules={{
            maxLength: issueTextLimit,
            required: `${t('FeedbackDesciption')}`,
          }}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              inputRef={field.ref}
              id="feedbackDetails"
              multiline
              rows={4}
              error={!!error}
              helperText={
                <Typography sx={styles.error}>{error?.message}</Typography>
              }
              sx={styles.inputField}
              aria-labelledby="Lbl_FeedbackDetails"
              data-testid="Txt_FeedbackDetails"
            />
          )}
        />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.001rem',
          color: tokens.colors.rsmGray.copy,
          paddingTop: '2rem',
        }}>
        <Typography
          component="legend"
          data-testid="Lbl_Feedback_ContactPreference"
          sx={{
            paddingBottom: '0rem',
          }}>
          <StyledLabel>
            <span data-testid="Lbl_FeedbackDetails">
              <Trans
                i18nKey="FeedbackPage.contactPreferenceHelperText"
                components={{ b: <b /> }}
              />
            </span>
          </StyledLabel>
        </Typography>

        <FormPreferredContactRadio
          control={control}
          handleRadioChange={handleRadioChange}
          contactMethod={preferredContact}
          data-testid="RdBtn_Feedback_preferredContactRadio"
        />
        <Box sx={styles.radioGroup}>
          {preferredContact === 'Email' && renderBusinessEmail()}
          {preferredContact === 'Phone' && (
            <Box sx={styles.contactMethodContainer}>
              {renderBusinessEmail()}
              {renderBusinessPhoneNumber()}
            </Box>
          )}
          {preferredContact === 'None' && renderBusinessEmail()}
        </Box>
      </Box>
      <Box sx={styles.submitButton}>
        <Button
          variant="contained"
          type="submit"
          data-testid="Btn_Feedback_SubmitButton">
          {t('FeedbackPage.SubmitButton')}
        </Button>
      </Box>
    </Box>
  )
}

export default FeedbackForm
