import { Theme } from '@mui/material'
import tokens from '../../styles/tokens.json'

export const useStyles = (theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, [col] 1fr)',
    gridTemplateRows: '[row] auto [row] 3.5rem [row] 5.3rem [row] auto',
    gap: '0.5rem ',
    width: '100%',
    height: '100%',
    flexShrink: 0,
    [theme.breakpoints.only('mobile')]: {
      height: '10.9375rem',
      gridTemplateRows: '[row] 1.875rem [row] auto [row] auto [row] 1.875rem',
      flexShrink: 0,
    },
  },
  appName: {
    gridArea: '2 row / 1 col / span 1 / span 10',
    paddingLeft: '1.5rem',
    fontFamily: 'Prelo-Medium, sans-serif',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: 'normal',
    height: '3.5rem',
    fontSize: '1.125rem',
    maxWidth: '11.875rem',
    [theme.breakpoints.only('mobile')]: {
      maxWidth: '100%',
      height: '2.25rem',
    },
  },
  appCategory: {
    fontFamily: 'Prelo-Light,sans-serif',
    fontSize: '0.75rem',
    lineHeight: 1.5,
  },
  appDesc: {
    gridArea: '3 row / 1 col / span 1 / span 12',
    paddingTop: '0.5rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    fontFamily: 'Prelo-Book,sans-serif',
    fontSize: '.75rem',
    fontWeight: 400,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    height: 'auto',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingRight: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      height: '5rem',
    },
  },
  appImage: {
    gridArea: '1 row / 1 col / span 1 / span 12',
    paddingRight: '1.5rem',
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
    [theme.breakpoints.only('mobile')]: {
      paddingRight: '2rem',
    },
  },
  appLink: {
    justifySelf: 'end',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontFamily: 'Prelo-Book,sans-serif',
    lineHeight: '1.5rem',
    gridArea: '4 row / 8 col / span 1 / span 5',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    height: '100%',
    width: '6.25rem',
    right: 0,
    bottom: 0,
    px: '0.25rem',
    py: '0.25rem',
    '&:focus': {
      boxShadow: `inset 0 0 0 0.125rem ${tokens.colors.white} !important`,
    },
  },
})

export default useStyles
