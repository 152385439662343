/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Box } from '@mui/material'
import { isFeatureFlagEnabled } from 'src/rsmCoreComponents/utils/featureFlagUtils'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import FaqTopic from '../../components/domain/Support/Faq/FaqTopic'
import RoboIcon from '../../components/icons/RoboIcon'
import { FaqTopicModel, getFaqs, setfaqData } from '../../store/faqs/faqsSlice'
import Spinner from '../../components/forms/Spinner/Spinner'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { Styles } from '../../types'
import { tokens } from '../../styles/materialTheme'
import ExpandButton from '../../components/Faq/ExpandButton/ExpandButton'
import faqData from './faqData.json'

const styles: Styles = {
  faqSection: (theme) => ({
    [theme.breakpoints.up('mobile')]: {
      paddingRight: '1rem',
      paddingLeft: '1rem',
    },
    [theme.breakpoints.up('tablet')]: {
      paddingRight: '2rem',
      paddingLeft: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: '3.5rem',
      display: 'flex',
    },
  }),
  faqFormtitle: (theme) => ({
    fontSize: '1.875rem',
    lineHeight: '2.5rem',
    color: tokens.colors.rsmGray.copy,
    [theme.breakpoints.up('mobile')]: { lineHeight: '3.5rem' },
    [theme.breakpoints.up('tablet')]: { fontSize: '3rem' },
    [theme.breakpoints.up('desktop')]: { fontSize: '3rem' },
  }),
  formDescription: (theme) => ({
    paddingTop: '1rem',
    paddingLeft: '0.25rem',
    paddingBottom: '2.5rem',
    color: '#515356',
    fontFamily: 'Prelo-Book',
    [theme.breakpoints.up('mobile')]: { width: '100%' },
  }),
  faqCard: (theme) => ({
    flex: '0.1 auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: tokens.colors.rsmGray.copy,
    margin: '0 auto',
    [theme.breakpoints.up('mobile')]: { maxWidth: '100%', padding: '0px 16px' },
    [theme.breakpoints.up('tablet')]: { maxWidth: '40.625rem' },
    [theme.breakpoints.up('desktop')]: { maxWidth: '40.625rem' },
  }),
  faqIcon: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }),
  faqErrorSection: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  errorTitle: (theme) => ({
    textAlign: 'center',
    [theme.breakpoints.up('mobile')]: { width: '100%' },
    [theme.breakpoints.up('tablet')]: { paddingTop: '36.1875rem' },
    [theme.breakpoints.up('desktop')]: { paddingTop: '36.1875rem' },
  }),
  error: () => ({
    paddingBottom: '1rem',
    paddingTop: '0.5rem',
    fontSize: '2rem',
  }),
  faqErrorDescription: (theme) => ({
    paddingBottom: '0.25rem',
    fontSize: '1.125rem',
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '1rem',
      paddingLeft: '1rem',
    },
  }),
  faqTopic: (theme) => ({
    paddingBottom: '2.5rem',
    backgroundColor: 'white',
    [theme.breakpoints.up('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.up('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.up('desktop')]: { paddingLeft: '3.75rem' },
  }),
  dataErrorCard: (theme) => ({
    display: 'flex',
    flexGrow: 1,
    paddingTop: '2.5rem',
    maxWidth: '100%',
    background: tokens.colors.white,
    border: tokens.colors.rsmGray.disabled,
    [theme.breakpoints.up('desktop')]: { flexGrow: 0, height: '55.5rem' },
    [theme.breakpoints.up('tablet')]: {
      flexGrow: 0,
      height: '27.625rem',
      marginTop: '0.625rem',
    },
    [theme.breakpoints.up('mobile')]: { height: '28.625rem' },
  }),
  button: (theme) => ({
    maxHeight: '3.125rem',
    marginTop: '1.875rem',
    alignContent: 'center',
    width: '6.6875rem',
    [theme.breakpoints.up('mobile')]: {
      width: '21.4375rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginBottom: '4rem',
      marginTop: '2rem',
    },
    [theme.breakpoints.up('tablet')]: { width: '6.6875rem' },
  }),
  mobileMt30: (theme) => ({
    [theme.breakpoints.up('mobile')]: { marginTop: '1.875rem' },
  }),
}

const FaqPage = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const faqRequest = useSelector(getFaqs)
  const isSupportEnabled = isFeatureFlagEnabled('Support')

  const getFaqData = () => {
    setIsLoading(true)
    dispatch(setfaqData(faqData.faqs as FaqTopicModel[]))
    setIsLoading(false)
  }

  useAITracking(t('Faq.FormTitle'), window.location.href)

  useEffect(() => {
    document.title = t('Faq.FormTitle')
    getFaqData()
  }, [])

  const content = (
    <>
      {isLoading && <Spinner open={isLoading} />}
      <Box sx={styles.faqSection}>
        <Box component="section">
          <Box
            component="h1"
            sx={styles.faqFormtitle}
            className="font-prelo-light"
            data-testid="Lbl_Faq_Title">
            {t('Faq.FormTitle')}
          </Box>
          <Box
            component="p"
            sx={styles.formDescription}
            data-testid="Lbl_Faq_Description">
            {t('Faq.FormDesc')}
          </Box>
        </Box>
      </Box>
      {(!isLoading && faqRequest?.length) === 0 ? (
        <Box sx={styles.dataErrorCard}>
          <Box sx={styles.faqCard}>
            <Box sx={styles.faqIcon}>
              <RoboIcon />
            </Box>
            <Box sx={styles.faqErrorSection}>
              <Box sx={styles.errorTitle}>
                <Box sx={styles.error}>{t('Faq.FaqErrorTitle')}</Box>
                <Box sx={styles.faqErrorDescription}>
                  {t('Faq.FaqErrorDesc')}
                </Box>
                <Button
                  sx={styles.button}
                  variant="contained"
                  type="submit"
                  onClick={() => window.location.reload()}
                  color="primary">
                  {t('Faq.Refresh')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.faqTopic}>
          <ExpandButton testId="Btn_Faq_ExpandAll" />
          {faqRequest?.map((faqTopic: FaqTopicModel, topicIndex: number) => (
            <FaqTopic
              key={faqTopic?.topic}
              topic={faqTopic}
              topicIndex={topicIndex}
            />
          ))}
        </Box>
      )}
      <Box sx={styles.mobileMt30} />
    </>
  )

  return isSupportEnabled ? (
    content
  ) : (
    <>
      <MainMenu currentItem="support" />
      <MainContent>{content}</MainContent>
    </>
  )
}

export default FaqPage
