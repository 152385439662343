import React from 'react'
import { Box, Link, styled } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import { isFeatureFlagEnabled } from 'src/rsmCoreComponents/utils/featureFlagUtils'
import { OKTA_DASHBOARD_URL } from '../../envVariables'
import { Styles } from '../../types'

const styles: Styles = {
  oktaLinkContainer: {
    marginTop: '2rem',
    textAlign: 'center',
    fontSize: '1rem',
    fontFamily: 'Prelo-Book, sans-serif',
    lineHeight: '1.5',
    color: 'tokens.colors.rsmGray.copy',
  },
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
}))

const OktaLink = () => {
  const { t } = useTranslation()

  const supportLink = isFeatureFlagEnabled('Support')
    ? '/contact-us/support-page'
    : '/support'

  return (
    <Box sx={styles.oktaLinkContainer}>
      <Box component="p">
        <Box sx={{ fontWeight: 'bold' }}>
          {t('HomePage.NotFindingWhatYouNeed')}
        </Box>
        <Box>
          <Trans
            i18nKey="HomePage.VisitAppManager"
            defaults={t('HomePage.VisitAppManager')}
            components={[
              <StyledLink
                href={OKTA_DASHBOARD_URL}
                target="_blank"
                rel="noopener noreferrer"
              />,
              <StyledLink href={supportLink} />,
            ]}
            values={{
              appManager: t('HomePage.AppManager'),
              here: t('HomePage.Here'),
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default OktaLink
