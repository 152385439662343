import React, { forwardRef } from 'react'
import { useTheme, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useDeviceType from 'src/rsmCoreComponents/hooks/useDeviceType'
import { UserAppModel } from '../../api/idm/getAllUserApplications'
import OverFlowToolTip from '../OverFlowToolTip/OverFlowToolTip'
import { useStyles } from './UserApp.styles'
import { AnalyticEvent } from '../../analytics/adobe/types'
import GreaterthanIcon from '../icons/GreaterthanIcon/GreaterthanIcon'
import RsmBarIcon from '../icons/RsmBarIcon/RsmBarIcon'

const analyticEventType: AnalyticEvent = 'Navigation_Click'

const UserApp = forwardRef<
  HTMLAnchorElement | null,
  { userApp: UserAppModel; index: number }
>((props, ref) => {
  const { userApp, index } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const styles = useStyles(theme)
  const { isMobile } = useDeviceType()

  const renderLaunchText = (appId: string | undefined): string => {
    switch (appId) {
      case 'teamdocs':
        return t('HomePage.ViewProjects')
      case 'moretools':
        return t('HomePage.Launch')
      default:
        return t('HomePage.Launch')
    }
  }

  return (
    <Box sx={styles.root} id="userAppContent">
      <Box sx={styles.appImage} aria-hidden="true">
        <RsmBarIcon />
      </Box>
      <Box
        component="h3"
        sx={styles.appName}
        key={userApp.id}
        id={userApp.id?.toString()}>
        <OverFlowToolTip
          testId={`Lbl_ToolTile_Title_${index}`}
          numberOfLines={2}>
          {userApp.name}
        </OverFlowToolTip>
        {!!userApp.category && (
          <Box aria-hidden="true" sx={styles.appCategory}>
            <OverFlowToolTip testId={`Lbl_ToolTile_Category_${index}`}>
              {userApp.category || ''}
            </OverFlowToolTip>
          </Box>
        )}
      </Box>
      {!!userApp.description && (
        <Box sx={styles.appDesc}>
          <OverFlowToolTip
            testId={`Lbl_ToolTile_Description_${index}`}
            numberOfLines={isMobile ? 2 : 4}>
            {userApp.description || ''}
          </OverFlowToolTip>
        </Box>
      )}
      <Button
        component="a"
        id={userApp.id?.toString()}
        sx={styles.appLink}
        ref={ref}
        href={userApp.launchUri ?? '/'}
        target={
          userApp.identifier === 'teamdocs' ||
          userApp.identifier === 'moretools'
            ? '_self'
            : '_blank'
        }
        color="primary"
        variant="contained"
        aria-label={
          userApp.identifier === 'teamdocs'
            ? `${userApp.name} - ${t('HomePage.ViewProjects')}`
            : ``
        }
        data-testid={`PendoClickArea_Lnk_Tool_Launch_${index}`}
        data-analytic-id={`PendoClickArea_Lnk_Tool_Launch_${userApp.identifier}`}
        data-analytic-event={analyticEventType}
        aria-describedby={userApp.id?.toString()}>
        <Box sx={{ paddingRight: '0.25rem' }}>
          {renderLaunchText(userApp.identifier)}
        </Box>
        <GreaterthanIcon height="8" />
      </Button>
    </Box>
  )
})

export default UserApp
