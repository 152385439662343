import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '..'

interface SupportState {
  isDashIssue: boolean
  selectedSupportCategory: number | undefined
}

const initialState: SupportState = {
  isDashIssue: false,
  selectedSupportCategory: undefined,
}

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setIsDashIssue: (state, action: PayloadAction<boolean>) => {
      state.isDashIssue = action.payload
    },
    setSelectedSupportCategory: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.selectedSupportCategory = action.payload
    },
  },
})

export const getIsDashIssue = (state: RootState) => state.support.isDashIssue
export const { setIsDashIssue } = supportSlice.actions

export const getSelectedSupportCategory = (state: RootState) =>
  state.support.selectedSupportCategory
export const { setSelectedSupportCategory } = supportSlice.actions

export default supportSlice.reducer
