import React, { useState, useEffect, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'usehooks-ts'
import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Styles } from '../../../types'
import tokens from '../../../styles/tokens.json'
import InvoicesFilterCategoryContainer from './InvoicesFilterCategoryContainer'
import {
  getInvoiceSelectedFilters,
  setFilterValue,
  InvoiceSelectedFiltersConstants,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'

const styles: Styles = {
  error: {
    color: tokens.colors.rsmRed.secondary,
    marginLeft: '1.063rem',
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
  },
  inputLabel: {
    backgroundColor: tokens.colors.white,
    paddingX: '0.0625rem',
  },
  textBox: {
    padding: '0rem 2.75rem 0rem 0.5625rem',
  },
}

interface InvoicesAmountRangeFilterProps {
  filterCategory: string
  clearCategoryFilter: (category: string) => void
  changeFilterAndSearch: (filter: string) => void
}

const InvoicesAmountRangeFilter = ({
  filterCategory,
  clearCategoryFilter,
  changeFilterAndSearch,
}: InvoicesAmountRangeFilterProps) => {
  const { t } = useTranslation()
  const selectedFilters = useSelector(getInvoiceSelectedFilters)
  const [min, setMin] = useState<string | null>(null)
  const [max, setMax] = useState<string | null>(null)
  const [minDebounceValue, setMinDebounceValue] = useState<string | null>(null)
  const [maxDebounceValue, setMaxDebounceValue] = useState<string | null>(null)
  const [minError, setMinError] = useState<string | null>(null)
  const [maxError, setMaxError] = useState<string | null>(null)
  const minDebounce = useDebounce(minDebounceValue, 500)
  const maxDebounce = useDebounce(maxDebounceValue, 500)

  const clearRange = () => {
    setMin(null)
    setMinDebounceValue(null)
    setMax(null)
    setMaxDebounceValue(null)
  }

  useEffect(() => {
    if (!maxError) {
      changeFilterAndSearch(
        setFilterValue(
          InvoiceSelectedFiltersConstants.invoiceAmountTo,
          maxDebounceValue,
        ),
      )
    }
    if (!minError) {
      changeFilterAndSearch(
        setFilterValue(
          InvoiceSelectedFiltersConstants.invoiceAmountFrom,
          minDebounceValue,
        ),
      )
    }
  }, [minDebounce, maxDebounce])

  useEffect(() => {
    const filterValue = selectedFilters.invoiceAmountFrom
    if ((filterValue === null || Number.isNaN(filterValue)) && min !== null) {
      setMin(null)
      setMinDebounceValue(null)
    } else if (
      filterValue !== null &&
      !Number.isNaN(filterValue) &&
      min === null
    ) {
      setMin(String(filterValue))
      setMinDebounceValue(String(filterValue))
    }
  }, [selectedFilters.invoiceAmountFrom])

  useEffect(() => {
    const filterValue = selectedFilters.invoiceAmountTo
    if ((filterValue === null || Number.isNaN(filterValue)) && max !== null) {
      setMax(null)
      setMaxDebounceValue(null)
    } else if (
      filterValue !== null &&
      !Number.isNaN(filterValue) &&
      max === null
    ) {
      setMax(String(filterValue))
      setMaxDebounceValue(String(filterValue))
    }
  }, [selectedFilters.invoiceAmountTo])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    filter: string,
  ) => {
    const value = e.target.value || null
    if (filter === InvoiceSelectedFiltersConstants.invoiceAmountFrom) {
      setMin(value)
      setMinDebounceValue(value)
    } else {
      setMax(value)
      setMaxDebounceValue(value)
    }
  }

  const validateNumber = (
    value: number,
    setError: (errorValue: string | null) => void,
  ) => {
    if (value < 0) {
      setError('NegativeNumberError')
    } else if (!/^\d*\.?\d{0,2}$/.test(String(value))) {
      setError('InvalidNumberError')
    } else {
      setError(null)
    }
  }

  useEffect(() => {
    const [minNum, maxNum] = [Number(min), Number(max)]
    validateNumber(minNum, setMinError)
    validateNumber(maxNum, setMaxError)
    // if values in textbox are invalid, attempt comparison against selectedFilters values
    const minComp =
      minNum && minNum > 0 ? minNum : selectedFilters.invoiceAmountFrom
    const maxComp =
      maxNum && maxNum > 0 ? maxNum : selectedFilters.invoiceAmountTo

    if (minNum && maxComp && minNum > maxComp) {
      setMinError('GreaterThanMaxError')
    }
    if (maxNum && minComp && maxNum < minComp) {
      setMaxError('LessThanMinError')
    }
  }, [min, max, setMinError, setMaxError])

  return (
    <InvoicesFilterCategoryContainer
      filterCategory={filterCategory}
      filterTitleKey="Invoicing.InvoicesAmountRangeFilter.Title"
      clearCategoryFilter={() => {
        clearRange()
        clearCategoryFilter(filterCategory)
      }}>
      <Box sx={styles.textBox}>
        <TextField
          id="minFilter"
          label={t('Invoicing.InvoicesAmountRangeFilter.Minimum')}
          data-testid={`Txt_Invoice_Min${filterCategory}`}
          margin="dense"
          InputLabelProps={{ sx: styles.inputLabel }}
          error={!!minError}
          variant="outlined"
          aria-label={t('Invoicing.InvoicesAmountRangeFilter.Minimum')}
          inputProps={{
            'aria-describedby': 'minFilterError',
          }}
          value={min === null ? '' : min}
          onChange={(e) => {
            handleChange(e, InvoiceSelectedFiltersConstants.invoiceAmountFrom)
          }}
        />
      </Box>
      {!!minError && (
        <Box
          id="minFilterError"
          sx={styles.error}
          component="span"
          role="alert">
          {t(`Invoicing.InvoicesAmountRangeFilter.${minError}`)}
        </Box>
      )}
      <Box sx={styles.textBox}>
        <TextField
          id="maxFilter"
          label={t('Invoicing.InvoicesAmountRangeFilter.Maximum')}
          data-testid={`Txt_Invoice_Max${filterCategory}`}
          margin="dense"
          InputLabelProps={{ sx: styles.inputLabel }}
          error={!!maxError}
          variant="outlined"
          aria-label={t('Invoicing.InvoicesAmountRangeFilter.Maximum')}
          inputProps={{
            'aria-describedby': 'maxFilterError',
          }}
          value={max === null ? '' : max}
          onChange={(e) => {
            handleChange(e, InvoiceSelectedFiltersConstants.invoiceAmountTo)
          }}
        />
      </Box>
      {!!maxError && (
        <Box
          id="maxFilterError"
          sx={styles.error}
          component="span"
          role="alert">
          {t(`Invoicing.InvoicesAmountRangeFilter.${maxError}`)}
        </Box>
      )}
    </InvoicesFilterCategoryContainer>
  )
}

export default InvoicesAmountRangeFilter
